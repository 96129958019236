import React from 'react'
import styled from 'styled-components'
import RichText from '../RichText'
import {
  VW,
  VARIABLE_CONSTANTS,
  typography,
  font,
} from '../../styles'

const BlogDescription = ({ text }) => {

  return (
    <BlogDescription_wrapper>
      <RichText json={text} />
    </BlogDescription_wrapper>
  )
}

const BlogDescription_wrapper = styled.div`
  ${typography.body.tiny}
  font-family: ${font.typewriter};
  line-height: 18px;
  max-width: 700px;
  margin: auto;
  text-align: center;

  p { margin: 0 }
`

export default BlogDescription