import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import BlogIntro from '../components/blog/BlogIntro'
import styled from 'styled-components'
import {
  VW,
  VARIABLE_CONSTANTS,
  colors
} from '../styles'
import BlogModules from '../components/BlogModules'
import BlogDescription from '../components/blog/BlogDescription'

const Blog = ({
  data: {
    contentfulBlogPage: {
      seoTitle,
      seoDescription,
      seoImage,
      topDescription,
      intro,
      sections
    }
  }
}) => {
  let combination = 0
  combination += topDescription ? 1 : 0
  combination += intro ? 2 : 0

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage && seoImage.fluid.src}
        slug='' />

      <Blog_wrapper>
        <Blog_container combination={combination}>
          {topDescription && <BlogDescription text={topDescription.json} />}
          {intro && <BlogIntro data={intro} />}
          <BlogModules sections={sections.reverse()} />
        </Blog_container>
      </Blog_wrapper>
    </>
  )
}

const {
  mobileHeader,
  desktopHeader,
  mobileBlogSpacing,
  desktopBlogSpacing
} = VARIABLE_CONSTANTS

const Blog_wrapper = styled.div`
  overflow: hidden;
`

/**
 * Combinations are as followed:
 * 0 => BlogModules
 * 1 => BlogDescription & BlogModules
 * 2 => BlogIntro & BlogModules
 * 3 => BlogDescription & BlogIntro & BlogModules
 */
const Blog_container = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;

  ${props => props.combination === 0 && `
    margin-top: calc(${mobileHeader}px + ${mobileBlogSpacing});
  `}

  ${props => props.combination === 1 && `
    [class*='BlogDescription_wrapper'] {
      margin-top: calc(${mobileHeader}px + ${mobileBlogSpacing});
      margin-bottom: ${mobileBlogSpacing};
    }
  `}

  ${props => props.combination === 2 && `
    [class*='BLogIntro_wrapper'] {
      margin-top: calc(${mobileHeader}px * 2);
      margin-bottom: ${mobileBlogSpacing};
    }

    [class*='BLogIntro_wrapper'] {
      margin-bottom: ${mobileBlogSpacing};
    }
  `}

  ${props => props.combination === 3 && `
    [class*='BlogDescription_wrapper'] {
      margin-top: calc(${mobileHeader}px + ${mobileBlogSpacing});
      margin-bottom: ${mobileBlogSpacing};
    }

    [class*='BLogIntro_wrapper'] {
      margin-bottom: ${mobileBlogSpacing};
    }
  `}
  
  @media (min-width: ${VW.desktop}) {

    ${props => props.combination === 0 && `
      margin-top: calc(${desktopHeader}px + ${desktopBlogSpacing});
    `}

    ${props => props.combination === 1 && `
      [class*='BlogDescription_wrapper'] {
        margin-top: calc(${desktopHeader}px + ${desktopBlogSpacing});
        margin-bottom: ${desktopBlogSpacing}
      }
    `}

    ${props => props.combination === 2 && `
      [class*='BLogIntro_wrapper'] {
        margin-top: calc(${desktopHeader}px * 2);
        margin-bottom: ${desktopBlogSpacing};
      }
    `}

    ${props => props.combination === 3 && `
      [class*='BlogDescription_wrapper'] {
        margin-top: calc(${desktopHeader}px + ${desktopBlogSpacing});
        margin-bottom: ${desktopBlogSpacing};
      }

      [class*='BLogIntro_wrapper'] {
        margin-bottom: ${desktopBlogSpacing};
      }
    `}
  }
`

export const PageQuery = graphql`
  query Blog($id: String!) {
    contentfulBlogPage(id: { eq: $id }) {
      seoTitle
      seoDescription
      seoImage {
        fluid {
          src
        }
      }
      topDescription {
        json
      }
      intro {
        ... on ContentfulBlogIntro {

          introText {
            json
          }
          introTitle
          introButton {
            urlLink
            title
          }
          introImage {
            fluid(maxWidth: 1600, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
        }
      }
      sections {
        ... on Node {
          ... on ContentfulBlogModuleVideo {
            id
            text {
              json
            }
            video {
              file {
                contentType
                url
              }
            }
            videoModal {
              videoButton {
                title
                urlLink
              }
              embeddedVideo {
                json
              }
              videoAsset {
                file {
                  url
                }
              }
              videoDescription {
                json
              }
              videoTitle
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulBlogModuleImageLink {
            id
            button {
              title
              urlLink
            }
            image {
              fluid(maxWidth: 1600, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            text {
              json
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulBlogModuleImageAsset {
            image {
              fluid(maxWidth: 1600, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulBlogModuleContent {
            id
            headline {
              json
            }
            text {
              json
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulBlogModuleCarousel {
            id
            images {
              fluid(maxWidth: 1600, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            text {
              json
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulBlogModuleSpotifyPlaylist {
            id
            spotifyEmbeded {
              json
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Blog