import React from 'react'
import styled from 'styled-components'
import Image from '../shared/Image'
import Button from '../Button'
import {
  icons,
  VW,
  typography
} from '../../styles'
import RichText from '../RichText'

const BlogIntro = ({
  data: {
    introText: {
      json: text
    },
    introTitle: title,
    introButton: {
      urlLink: buttonLink,
      title: buttonTitle
    },
    introImage: {
      fluid: image,
      description: imageAlt
    }
  }
}) => {
  return (
    <BLogIntro_wrapper>
      <Image_wrapper>
        <Image
          fluid={image}
          alt={imageAlt} />
      </Image_wrapper>
      <Content_wrapper>
        <Content_container>
          <Title_wrapper>
            {icons.red_logo}
            <Title>{title}</Title>
          </Title_wrapper>
          <Text_wrapper>
            <RichText json={text} />
          </Text_wrapper>
          <Button_wrapper>
            <Button
              title={buttonTitle}
              link={buttonLink}
              type='small'
              color='black' />
          </Button_wrapper>
        </Content_container>
      </Content_wrapper>
    </BLogIntro_wrapper>
  )
}

const LEFT_PADDING = '70px'

const BLogIntro_wrapper = styled.div`

  &,
  * {
    box-sizing: border-box;
  }

  @media (min-width: ${VW.desktop}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 20px;
    margin-bottom: 110px;
  }
`

const Image_wrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 9;
`

const Content_wrapper = styled.div`
  position: relative;
  grid-column-start: 10;
  grid-column-end: 13;
  margin-top: 15px;

  @media (min-width: ${VW.desktop}) {
    margin: 0;
  }
`

const Content_container = styled.div`
  max-width: 500px;

  @media (min-width: ${VW.desktop}) {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    max-width: initial;
  }
`

const Title_wrapper = styled.div`
  position: relative;

  svg {
    height: 63px;
    width: 49px;
    position: absolute;
    top: 9px;
    left: 0;
  }

  @media (min-width: ${VW.desktop}) {
    svg {
      height: 97px;
      width: 76px;
      right: calc(100% * 1.205167);
      left: initial;
    }
  }

  @media (min-width: 1200px) {
    svg {
      right: calc(100% + 62px);
      left: initial;
    }
  }
`

const Title = styled.div`
  ${typography.headlines.regular}
  padding-left: ${LEFT_PADDING};

  @media (min-width: ${VW.desktop}) {
    ${typography.headlines.articleAlternate}
    padding-left: 0;
  }
`

const Text_wrapper = styled.div`
  ${typography.body.small}
  padding-left: ${LEFT_PADDING};

  p {
    magin-top: 0;
  }

  @media (min-width: ${VW.desktop}) {
    margin-top: 1em;
    padding-left: 0;
  }
`

const Button_wrapper = styled.div`
  padding-left: ${LEFT_PADDING};
  a {
    display: inline-block;
  }

  @media (min-width: ${VW.desktop}) {
    padding-left: 0;
  }
`

export default BlogIntro